// AccountComponent.js
import React, { useState, useContext, useEffect, FC, ChangeEvent } from "react";
import {
  TextField,
  Button,
  Typography,
  Container,
  Paper,
  Switch,
  FormControlLabel,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { createAccount } from "../../service/accountServices";
import useAxiosInstance from "../../service/useAxiosInstance";
import { msalInstance } from "../../service/msalConfig";
import { useCurrentAccountInfo } from "../../utils/useCurrentAccountInfo";
import authConfig from "../../config/app-config.json";
import { navigate } from "gatsby";
import { styles } from "../../styles/generalStyles";
import { AlertContext } from "../../utils/context";
import {
  Account,
  CountryListProps,
} from "../../dataTypes/account/accountTypes";

const baseURL = authConfig.manageAccountBaseURL;
function CountryList(country: CountryListProps) {
  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    country.onChange(e);
  };
  return (
    <Grid item xs={12} sm={6}>
      <FormControl margin="normal" fullWidth>
        <InputLabel>{country.label}</InputLabel>
        <Select
          defaultValue={country.value}
          id={country.id}
          name={country.name}
          label={country.label}
          onChange={handleSelectChange}
        >
          {["CAN", "USA"].map(function (key) {
            return <MenuItem value={key}>{key}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </Grid>
  );
}
const OpenAccountComponent: FC<{ compliment: boolean }> = (props) => {
  const alertFunction = useContext(AlertContext);

  const updateError = (error) => alertFunction("updateError", error);
  const updateSuccess = (data, refresh, message) =>
    alertFunction("updateSuccess", { data, refresh, message });
  const { username, warehouseLocation } = useCurrentAccountInfo();
  const axiosInstance = useAxiosInstance(baseURL, msalInstance);
  const [account, setAccount] = useState<Account>({
    type: props.compliment ? "Goldstar Complimentary" : "Goldstar",
    country: "USA",
    awarenessCode: "",
    createdAtLocationNumber: undefined,
    tier: "Regular",
    autoRenew: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState<Account>();

  const handleChange = (e: any) => {
    const { name, value, checked } = e.target;

    setAccount({ ...account, [name]: name === "autoRenew" ? checked : value });
  };

  const handleSubmit = async (e: React.MouseEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const audit = {
        createdByChannel: "Desktop",
        CreatedByUser: username,
        createdAtLocationNumber: account.createdAtLocationNumber,
      };
      await createAccount(
        axiosInstance,
        props.compliment
          ? "open-consumer-complimentary-account"
          : "open-consumer-account",
        {
          locationNumber: account.createdAtLocationNumber,
          ...audit,
          ...account,
          audit,
        },
        setData,
        updateSuccess,
        updateError
      );
    } catch (error) {
      setIsSubmitting(false);
      console.error("Update error:", error);
    }
  };

  useEffect(() => {
    if (data) {
      setTimeout(
        () =>
          navigate(
            `/ManageAccount/retrieveAccount?accountID=${data.accountId}`
          ),
        2000
      );
    }
  }, [data]);

  //Don't like using two containers, but it is implemented differently than OpenBusinessAccount,
  //for example and I want them to match
  return (
    <Container disableGutters sx={styles.cardWidth}>
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Open Account
        </Typography>
        <TextField
          margin="normal"
          fullWidth
          name="type"
          label="Type"
          variant="outlined"
          value={account.type}
          disabled={true}
        />
        <CountryList
          margin="normal"
          fullWidth
          name="country"
          label="Account Country"
          variant="outlined"
          value={account.country ?? ""}
          onChange={handleChange}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              margin="normal"
              fullWidth
              name="awarenessCode"
              label="Awareness Code"
              variant="outlined"
              value={account.awarenessCode}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              margin="normal"
              fullWidth
              name="awarenessDescription"
              label="Awareness Description"
              variant="outlined"
              value={account.awarenessDescription}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              margin="normal"
              fullWidth
              name="createdAtLocationNumber"
              label="Location Number"
              variant="outlined"
              value={account.createdAtLocationNumber}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              margin="normal"
              fullWidth
              name="tier"
              label="Tier"
              variant="outlined"
              value={account.tier}
              disabled
            />
          </Grid>
        </Grid>
        <FormControlLabel
          name="autoRenew"
          labelPlacement="start"
          control={
            <Switch
              checked={account.autoRenew}
              onChange={handleChange}
              inputProps={{ "aria-label": "Auto Renew" }}
            />
          }
          label="Auto Renew"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ m: 3 }}
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting
            ? "Creating..."
            : `Open ${props.compliment ? "Complimentary" : ""} Goldstar Account`}
        </Button>
      </Paper>
    </Container>
  );
};

export default OpenAccountComponent;
