import React, { FC } from "react";
import {
  Box,
  Button,
  Paper,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Container,
} from "@mui/material";
import buttons from "../../data/cardEligibility.json";
import { styles } from "../../styles/generalStyles";
import { CardEligibilityComponentProps } from "../../dataTypes/card/cardComponentsTypes";

const CardEligibilityComponent: FC<CardEligibilityComponentProps> = ({
  getEligibility,
  checked,
  setChecked,
}) => {
  return (
    <Container disableGutters sx={styles.cardWidth}>
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6" sx={{ mb: 5 }}>
          Card Eligibility
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mh: 3 }}
        >
          <RadioGroup
            row
            aria-labelledby="control-label-placement"
            name="position"
            defaultValue="top"
            sx={{ ml: 2 }}
          >
            {buttons.map((button, index) => (
              <FormControlLabel
                value="top"
                control={<Radio />}
                label={button.label}
                labelPlacement="top"
                checked={checked === index}
                onClick={() => setChecked(index)}
                key={index}
              />
            ))}
          </RadioGroup>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={getEligibility}
          >
            {"Verify"}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default CardEligibilityComponent;
