import React, { FC } from "react";
import { TextField, TextFieldProps } from "@mui/material";

type InputFieldProps = TextFieldProps & {
  name: string;
  label: string;
  value: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const InputField: FC<InputFieldProps> = ({
  name,
  label,
  value,
  onChange,
  type = "text",
  disabled = false,
  ...rest
}) => {
  return (
    <TextField
      margin="normal"
      fullWidth
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      type={type}
      disabled={disabled}
      InputLabelProps={type === "date" ? { shrink: true } : undefined}
      {...rest}
    />
  );
};

export default InputField;
