import { Grid, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import React, { FC } from "react";

type OptionType = 
  | [string, string] // Format for key-value pairs
  | { key: string; value: string; disabled?: boolean }; // Format for objects with a disabled field

type DropdownMenuProps = {
  field: any;
  options: OptionType[]; // Allow both formats
};

const DropdownMenu: FC<DropdownMenuProps> = ({ field, options }) => {
  return (
    <Grid item xs={12} sm={6}>
      <FormControl margin="normal" fullWidth>
        <InputLabel>{field.label}</InputLabel>
        <Select
          value={field.value}
          id={field.id}
          name={field.name}
          label={field.label}
          onChange={field.onChange}
        >
          {options.map((option, index) => {
            // Handle key-value pair format
            if (Array.isArray(option)) {
              const [key, value] = option;
              return (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              );
            }

            // Handle object format with `disabled` field
            const { key, value, disabled } = option;
            return (
              <MenuItem key={key} value={key} disabled={disabled}>
                {value}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default DropdownMenu;