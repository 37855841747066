import React, { FC } from "react";
import { Container, Paper, TextField, Typography } from "@mui/material";
import { navigate } from "gatsby";
import { styles } from "../../styles/generalStyles";
import { CardMemberComponentsProps } from "../../dataTypes/card/cardComponentsTypes";

const CardMemberComponent: FC<CardMemberComponentsProps> = ({ memberData }) => {
  return (
    <Container disableGutters sx={styles.cardWidth}>
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Member Information
        </Typography>
        <TextField
          margin="normal"
          fullWidth
          name="memberID"
          label="Member ID"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          value={memberData?.memberId || ""}
          InputProps={styles.underlineInputProps}
          onClick={() =>
            navigate(
              `/ManageMember/retrieveMember?memberID=${memberData?.memberId}`
            )
          }
        />
        <TextField
          margin="normal"
          fullWidth
          disabled
          name="legacyMemberId"
          label="Legacy Member ID"
          variant="outlined"
          value={memberData?.legacyMemberId || ""}
        />
        <TextField
          margin="normal"
          fullWidth
          disabled
          name="status"
          label="Status"
          variant="outlined"
          value={memberData?.status || ""}
        />
        <TextField
          margin="normal"
          fullWidth
          disabled
          name="lrole"
          label="Role"
          variant="outlined"
          value={memberData?.role || ""}
        />
      </Paper>
    </Container>
  );
};

export default CardMemberComponent;
