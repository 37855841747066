import React, { FC, useContext, useState } from "react";
import { Container } from "@mui/material";
import SearchComponent from "../../components/SearchComponent";
import RetrievePerson from "../ManagePerson/retrievePerson";
import RetrieveCard from "../ManageCard/retrieveCard";
import RetrieveMember from "../ManageMember/retrieveMember";
import RetrieveOrganization from "../ManageOrganization/retrieveOrganization";
import RetrieveAccount from "../ManageAccount/retrieveAccount";
import useAxiosInstance from "../../service/useAxiosInstance";
import authConfig from "../../config/app-config.json";
import { axiosGetV2 } from "../../service/axiosApi";
import { msalInstance } from "../../service/msalConfig";
import { useFetchByText } from "../../utils/useFetchByText";
import TextSearchResultsComponent from "../../components/TextSearchResultsComponent";
import { AlertContext, CountryCodeContext } from "../../utils/context";
import { Country } from "../../dataTypes/shared/locationTypes";
import { GetTextResults } from "../../dataTypes/search/searchTypes";
import { CardPlaceholder, CardResponse } from "../../dataTypes/card/cardTypes";
import { TextSearchPersonResponse } from "../../dataTypes/search/textSearchTypes";

const {
  baseManageMemberUrl,
  manageAccountBaseURL,
  manageOrganizationBaseURL,
  basePersonUrlV3,
} = authConfig;

const RetrieveSearch: FC = () => {
  const [searchId, setSearchId] = useState<string | null>();
  const [resultDetails, setResultDetails] = useState<
    CardResponse | CardPlaceholder | null
  >(null);
  const [textSearchDetails, setTextSearchDetails] =
    useState<TextSearchPersonResponse | null>(null);
  const countryCode: Country = useContext(CountryCodeContext);
  const getTextResults: GetTextResults = useFetchByText();
  const alertFunction = useContext(AlertContext);

  const updateSuccess = (data: {}) => alertFunction("updateSuccess", { data });
  const updateError = (error: Error) => alertFunction("updateError", error);
  const updateTextSearchSuccess = (data: {}) =>
    alertFunction("updateTextSearchSuccess", data);

  const axiosInstanceMember = useAxiosInstance(
    baseManageMemberUrl,
    msalInstance
  );
  const axiosInstanceAccount = useAxiosInstance(
    manageAccountBaseURL,
    msalInstance
  );
  const axiosInstanceOrganization = useAxiosInstance(
    manageOrganizationBaseURL,
    msalInstance
  );
  const axiosInstancePerson = useAxiosInstance(basePersonUrlV3, msalInstance);
  const headers = { headers: { "country-code": countryCode } };

  //Passing "() => {}" because we don't want any alerts while going through each entity,
  //will call updateSuccess when needed
  const getMember = (id: string) =>
    axiosGetV2(
      axiosInstanceMember,
      `retrieve-member-details?memberId=${id}`,
      headers,
      setResultDetails,
      () => {},
      () => {}
    );
  const getAccount = (id: string) =>
    axiosGetV2(
      axiosInstanceAccount,
      `retrieve-account-details?accountId=${id}`,
      headers,
      setResultDetails,
      () => {},
      () => {}
    );
  const getOrganization = (id: string) =>
    axiosGetV2(
      axiosInstanceOrganization,
      `retrieve-organization-details?organizationid=${id}`,
      headers,
      setResultDetails,
      () => {},
      () => {}
    );
  const getPerson = (id: string) =>
    axiosGetV2(
      axiosInstancePerson,
      `retrieve-person-details?personID=${id}`,
      headers,
      setResultDetails,
      () => {},
      () => {}
    );

  const handleSearchIDChange = (id: string) => {
    setResultDetails(null);
    setSearchId(id);
    isCard(id);
  };

  const isCard = (id: string) => /^[1-9]{1}[0-9]{5,14}$/i.test(id);
  const isGUID = (id: string) =>
    /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/i.test(id);

  const retrieveResult = (id: string) => {
    setResultDetails(null);

    getMember(id).then((result: { id: string }) => {
      if (result?.id) {
        updateSuccess(result);
        return;
      } else
        getAccount(id).then((result: { id: string }) => {
          if (result?.id) {
            updateSuccess(result);
            return;
          } else
            getPerson(id).then((result: { person: {} }) => {
              if (result?.person) {
                updateSuccess(result);
                return;
              } else
                getOrganization(id).then((result: { id: string }) => {
                  if (result?.id) {
                    updateSuccess(result);
                    return;
                  } else updateSuccess(result);
                });
            });
        });
    });
  };

  const getSearchResult = (id: string) => {
    const cardData: CardPlaceholder = { docType: "cardView" };

    if (isCard(id)) {
      setResultDetails(cardData);
      return;
    } else if (isGUID(id)) {
      retrieveResult(id);
      return;
    } else {
      getTextResults(
        id,
        false,
        countryCode,
        setTextSearchDetails,
        updateTextSearchSuccess,
        updateError
      );
    }
  };

  const searchEntity = (data: CardResponse | CardPlaceholder | null) => {
    if (data?.docType) {
      switch (data.docType) {
        case "personView":
          return (
            <RetrievePerson
              fromAnySearch={true}
              searchId={searchId!}
              code={countryCode}
            />
          );
        case "cardView":
          return <RetrieveCard fromAnySearch={true} searchId={searchId} />;
        case "memberView":
          return (
            <RetrieveMember
              fromAnySearch={true}
              searchId={searchId as string}
            />
          );
        case "accountView":
          return (
            <RetrieveAccount
              fromAnySearch={true}
              searchId={searchId}
              code={countryCode}
            />
          );
        case "organizationView":
          return (
            <RetrieveOrganization
              fromAnySearch={true}
              searchId={searchId!}
              code={countryCode}
            />
          );
        default:
          <></>;
      }
    }
  };

  const textSearch = (data: TextSearchPersonResponse | null) =>
    data ? <TextSearchResultsComponent data={data.results} /> : <></>;

  return (
    <Container disableGutters sx={{ justifyContent: "center" }}>
      <SearchComponent
        callback={getSearchResult}
        label={"Enter Any ID"}
        handleIdChange={handleSearchIDChange}
      />
      <Container disableGutters sx={{ justifyContent: "center" }}>
        {searchEntity(resultDetails) || textSearch(textSearchDetails)}
      </Container>
    </Container>
  );
};

export default RetrieveSearch;
