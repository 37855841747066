import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import cardActions from "../../data/cardActions.json";
import cardTypes from "../../data/cardTypes.json";
import countryList from "../../data/countryList.json";
import { styles } from "../../styles/generalStyles";
import { CardComponentProps } from "../../dataTypes/card/cardComponentsTypes";
import { Action } from "../../dataTypes/shared/sharedRequestTypes";
import { Card } from "../../dataTypes/card/cardTypes";
import InputField from "../common/InputField";
import DropdownMenu from "../common/DropdownMenu";

const CardComponent: FC<CardComponentProps> = ({
  cardData,
  onUpdate,
  onAction,
  onReplace,
}) => {
  const [card, setCard] = useState<Card>(cardData);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCard({ ...card, [name]: value });
  };

  // use when date requires ISO Format String
  const handleStatusDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = event.target.value;
    
    if (selectedDate) {
      const isoDate = new Date(selectedDate).toISOString();
      setCard((prevCard) => ({ ...prevCard, statusDate: isoDate }));
    } else {
      setCard((prevCard) => ({ ...prevCard, statusDate: "" }));
    }
  };

  const handleUserAction = (e: React.MouseEvent, action: Action) => {
    e.preventDefault();
    setIsSubmitting(true);
    onAction(card, action);
    setIsSubmitting(false);
  };

  const handleOverWrite = (event: React.MouseEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    onUpdate(card);
    setIsSubmitting(false);
  };

  const handleReplaceCard = (event: React.MouseEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    onReplace();
    setIsSubmitting(false);
  };

  const hasActions: boolean = cardData?.actions?.length > 0;
  const actions: Action[] = hasActions
    ? cardData?.actions
    : (cardActions as Action[]);

  return (
    <Container sx={styles.cardWidth} disableGutters>
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Card Information
        </Typography>

        <DropdownMenu
          field={{
            label: "Type",
            value: card.type || "",
            id: "cardTypeDropdown",
            name: "type",
            onChange: handleChange,
          }}
          options={Object.entries(cardTypes)}
        />

        <InputField
          name="validFrom"
          label="Valid From"
          value={card?.validFrom || ""}
          type="date"
          onChange={handleChange}
        />

        <DropdownMenu
          field={{
            label: "Country",
            value: card.country || "",
            id: "countryDropdown",
            name: "country",
            onChange: handleChange,
          }}
          options={Object.entries(countryList)}
        />

        <Grid container spacing={2}>
        <DropdownMenu
          field={{
            label: "Status",
            value: card?.status || "",
            id: "statusDropdown",
            name: "status",
            onChange: handleChange,
          }}
          options={[
            ["Issued", "Issued"],
            ["Active", "Active"],
            ["Cancelled", "Cancelled"],
            ["Compromised", "Compromised"],
          ]}
        />
          <Grid item xs={12} md={6}>
            <InputField
              name="statusDate"
              label="Status Date"
              value={card?.statusDate ? card?.statusDate?.split("T")[0] : ""}
              type="date"
              onChange={handleStatusDateChange}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item key="overwrite">
            <Button
              type="submit"
              variant="contained"
              color="error"
              sx={{ mt: 3, mr: 3 }}
              onClick={handleOverWrite}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Updating..." : "Overwrite Card"}
            </Button>
          </Grid>

          {actions.map((action: Action) => (
            <Grid item key={action.text}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ mt: 3, mr: 3 }}
                onClick={(e) => handleUserAction(e, action)}
                disabled={isSubmitting || !hasActions}
              >
                {isSubmitting ? "Updating..." : action.text}
              </Button>
            </Grid>
          ))}

          <Grid item key="replace">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 3, mr: 3 }}
              onClick={handleReplaceCard}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Updating..." : "Replace Card"}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default CardComponent;