import React, { useContext, useState, useEffect } from "react";
import { Typography, Container, Snackbar, Alert, Paper } from "@mui/material";
import AccountComponent from "../../components/AccountComponents/AccountComponent";
import SearchComponent from "../../components/SearchComponent";
import AccountMemberComponent from "../../components/AccountComponents/AccountMemberComponent";
import { useLocation } from "@reach/router";
import { updateAccount } from "../../service/accountServices";
import { useCurrentAccountInfo } from "../../utils/useCurrentAccountInfo";
import useAxiosInstance from "../../service/useAxiosInstance";
import { msalInstance } from "../../service/msalConfig";
import { axiosGetV2 } from "../../service/axiosApi";
import authConfig from "../../config/app-config.json";
import { useFetchByText } from "../../utils/useFetchByText";
import TextSearchResultsComponent from "../../components/TextSearchResultsComponent";
import { styles } from "../../styles/generalStyles";
import { AlertContext } from "../../utils/context";
import {
  Account,
  AccountDetails,
  AccountUpdatePayload,
} from "../../dataTypes/account/accountTypes";
import {
  PersonResult,
  OrgResult,
} from "../../dataTypes/search/textSearchTypes";
import { ErrorResponse, SuccessData } from "../../dataTypes/alert/alertTypes";

const baseURL = authConfig.manageAccountBaseURL;

const RetrieveAccount = ({ fromAnySearch, searchId, code }) => {
  const [accID, setAccID] = useState<string>(searchId);
  const { username, warehouseLocation } = useCurrentAccountInfo();
  const [accountDetails, setAccountDetails] = useState<AccountDetails>();
  const [isFromText, setIsFromText] = useState(false);
  const [textSearchResults, setTextSearchResults] = useState<{
    results: PersonResult[] | OrgResult[];
  }>();
  const axiosInstance = useAxiosInstance(baseURL, msalInstance);
  const getTextResults = useFetchByText();

  const alertFunction = useContext(AlertContext);
  const updateError = (error: ErrorResponse) =>
    alertFunction("updateError", error);
  const updateSuccess = (
    data: SuccessData,
    refresh?: boolean |  (() => boolean),
    message?: string
  ) => {
    alertFunction("updateSuccess", { data, refresh, message });
  };
  const headers = { "country-code": code };

  const handleAccIDChange = (id: string) => setAccID(id);

  useEffect(() => {
    if (fromAnySearch) handleSearch(searchId);
  }, []);

  const location = useLocation();

  useEffect(() => {
    // Parse the query string to get the accID value
    const queryParams = new URLSearchParams(location.search);
    const accountIdFromQuery = queryParams.get("accountID");
    if (accountIdFromQuery) {
      setAccID(accountIdFromQuery);
      if (location.pathname === "/ManageAccount/retrieveAccount/")
        handleSearch(accountIdFromQuery);
    }
  }, [location.search]);

  const handleSearch = (id: string) => {
    setAccountDetails(undefined);

    const isAccountId =
      /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/i.test(id);

    if (isAccountId) {
      setIsFromText(false);
      axiosGetV2(
        axiosInstance,
        `retrieve-account-details/?accountId=${id}`,
        { headers },
        setAccountDetails,
        updateSuccess,
        updateError
      );
    } else {
      setIsFromText(true);
      getTextResults(
        id,
        false,
        code,
        setTextSearchResults,
        (data: Account) => alertFunction("updateTextSearchSuccess", data),
        (error: ErrorResponse) => alertFunction("updateError", error)
      );
    }
  };

  const refreshAccount = () => {
    handleSearch(accID);
    return true;
  };
  const handleUpdate = async (
    updateData: AccountUpdatePayload,
    onSuccessMessage: string
  ) => {
    try {
      updateData.audit = {
        createdByUser: username,
        createdAtLocationNumber: warehouseLocation,
      };
      const updateOverwriteSuccess = (data: SuccessData) =>
        updateSuccess(data, refreshAccount, onSuccessMessage);
      await updateAccount(
        axiosInstance,
        updateData,
        updateOverwriteSuccess,
        updateError,
        headers
      );
    } catch (error) {
      console.error("Update error:", error);
    }
  };
  return (
    <Container disableGutters sx={{ justifyContent: "center" }}>
      {!fromAnySearch && (
        <SearchComponent
          callback={() => handleSearch(accID)}
          label={"Enter Account ID"}
          handleIdChange={handleAccIDChange}
        />
      )}
      {accountDetails && !isFromText ? (
        <>
          <AccountComponent
            accountData={accountDetails.account}
            accountId={accountDetails.accountId ?? ""}
            onUpdate={(data) =>
              handleUpdate(data.payload, `Account ${data.code} successfully!`)
            }
          />
          {accountDetails.members ? (
            <AccountMemberComponent memberInfoData={accountDetails.members} />
          ) : (
            <Container disableGutters sx={styles.cardWidth}>
              <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Member Information
                </Typography>
                <Typography>Members not available.</Typography>
              </Paper>
            </Container>
          )}
        </>
      ) : (
        isFromText &&
        textSearchResults?.results && (
          <TextSearchResultsComponent data={textSearchResults?.results} />
        )
      )}
    </Container>
  );
};

export default RetrieveAccount;
