import { Alert, Container, Grid, Snackbar } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import PersonProfile from "../../components/PersonManagementComponents/PersonProfile";
import PersonContact from "../../components/PersonManagementComponents/PersonContact";
import PersonAddress from "../../components/PersonManagementComponents/PersonAddress";
import PersonActions from "../../components/PersonManagementComponents/PersonActions";
import PersonPrivacyConsent from "../../components/PersonManagementComponents/PersonPrivacyConsent";
import ErrorPopupComponent from "../../components/ErrorPopupComponent";
import PersonMarketingPreference from "../../components/PersonManagementComponents/PersonMarketingPreference";
import PersonMember from "../../components/PersonManagementComponents/PersonMember";
import { useLocation } from "@reach/router";
import { generateUUID } from "../../utils/generateUUID";
import SearchComponent from "../../components/SearchComponent";
import { useCurrentAccountInfo } from "../../utils/useCurrentAccountInfo";
import useAxiosInstance from "../../service/useAxiosInstance";
import { msalInstance } from "../../service/msalConfig";
import {
  axiosGetPromise,
  axiosPutPromise,
  axiosPostPromise
} from "../../service/axiosApi";
import authConfig from "../../config/app-config.json";
import CountryPickerComponent from "../../components/CountryPickerComponent";
import { useFetchByText } from "../../utils/useFetchByText";
import TextSearchResultsComponent from "../../components/TextSearchResultsComponent";
import { getDefaultPrivacyConsent } from "../../utils/getDefaultPrivacyConsent";
import { styles } from "../../styles/generalStyles";
import { AlertContext } from "../../utils/context";
import {
  PersonInfoResponse,
  PersonViewResponse
} from "../../dataTypes/person/personRequestTypes";
import {
  Address,
  PrivacyConsent,
  Residence,
  UpdateData
} from "../../dataTypes/person/personTypes";
import { TextSearchPersonResponse } from "../../dataTypes/search/textSearchTypes";
import { Country } from "../../dataTypes/shared/locationTypes";
import { Audited, Audit } from "../../dataTypes/shared/sharedRequestTypes";

const baseURLV3 = authConfig.basePersonUrlV3;

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function adjustNullsBasedOnDefaults<T extends {}>(defaults: T, data: T) {
  let clonedObject: T | null = null;

  Object.keys(defaults).forEach((k) => {
    if (defaults[k] === null && data[k] !== null) {
      if (clonedObject === null) {
        clonedObject = { ...data };
      }
      clonedObject[k] = null;
    } else if (defaults[k] !== null && data[k] === null) {
      if (clonedObject === null) {
        clonedObject = { ...data };
      }
      clonedObject[k] = defaults[k];
    }
  });

  return clonedObject || data;
}

function adjustPrivacyConsentDataByState(
  data: Audited<PrivacyConsent>,
  address: Address,
) {
  const defaultPrivacyConsent = getDefaultPrivacyConsent(
    address.country,
    address.stateProvince,
  );

  const objectToMarketing = adjustNullsBasedOnDefaults(
    defaultPrivacyConsent.objectToMarketing,
    data.objectToMarketing,
  );
  const restrictPIUsage = adjustNullsBasedOnDefaults(
    defaultPrivacyConsent.restrictPIUsage,
    data.restrictPIUsage,
  );
  const rejectPIProcessing = adjustNullsBasedOnDefaults(
    defaultPrivacyConsent.rejectPIProcessing,
    data.rejectPIProcessing,
  );

  if (
    objectToMarketing !== data.objectToMarketing ||
    restrictPIUsage !== data.restrictPIUsage ||
    rejectPIProcessing !== data.rejectPIProcessing
  ) {
    return {
      ...data,
      objectToMarketing: { ...objectToMarketing },
      restrictPIUsage: { ...restrictPIUsage },
      rejectPIProcessing: { ...rejectPIProcessing },
    };
  } else {
    return data;
  }
}

function mergePrivacyConsentDefaults(data: any) {
  const defaultPrivacyConsent = getDefaultPrivacyConsent(
    data.residence.address.country,
    data.residence.address.stateProvince,
  );

  return {
    ...data,
    privacyConsent: {
      objectToMarketing: {
        ...defaultPrivacyConsent.objectToMarketing,
        ...data.privacyConsent.objectToMarketing,
      },
      restrictPIUsage: {
        ...defaultPrivacyConsent.restrictPIUsage,
        ...data.privacyConsent.restrictPIUsage,
      },
      rejectPIProcessing: {
        ...defaultPrivacyConsent.rejectPIProcessing,
        ...data.privacyConsent.rejectPIProcessing,
      },
    },
  };
}

interface RetrivePersonProps {
  fromAnySearch: string | boolean;
  searchId: string;
  code: string;
}

const RetrievePerson = ({
  fromAnySearch,
  searchId,
  code,
}: RetrivePersonProps) => {
  // Parse the query string to get the personID value
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const personIdFromQuery = queryParams.get("personID") as string;
  const countryCodeFromQuery = queryParams.get("countryCode") as Country;
  const { username, warehouseLocation } = useCurrentAccountInfo();
  const [personID, setPersonID] = useState(searchId);
  const [personInfo, setPersonInfo] = useState<PersonInfoResponse | null>(null);
  const [personDetails, setPersonDetails] = useState<PersonViewResponse | null>(
    null,
  );
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [errorDetails, setErrorDetails] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [countryCode, setCountryCode] = useState(
    code || countryCodeFromQuery || "USA",
  );
  const [isFromText, setIsFromText] = useState(false);
  const [textSearchResults, setTextSearchResults] =
    useState<TextSearchPersonResponse | null>(null);
  const axiosInstanceV3 = useAxiosInstance(baseURLV3, msalInstance);
  const getTextResults = useFetchByText();
  const alertFunction = useContext(AlertContext);
  const handleSnackbarClose = () => setSnackbarOpen(false);
  const handleErrorClose = () => setErrorPopupOpen(false);
  const handlePersonIDChange = (id: string) => {
    const enteredPersonId = id.trim();
    setPersonID(enteredPersonId);
  };

  const headers = { "country-code": countryCode };
  const isProtectedPerson =
    personInfo?.privacyConsent.restrictPIUsage.privacyLevel !== "Default";

  useEffect(() => {
    if (fromAnySearch) handleSearch(searchId);
  }, []);

  useEffect(() => {
    // Check if a person ID is available in the query parameters
    if (personIdFromQuery) {
      setPersonID(personIdFromQuery);
      if (
        personIdFromQuery !== "" &&
        location.pathname === "/ManagePerson/retrievePerson/"
      ) {
        handleSearch(personIdFromQuery);
      }
    }
    if (countryCodeFromQuery) {
      setCountryCode(countryCodeFromQuery);
    }
  }, [personIdFromQuery, countryCodeFromQuery, location.pathname]);

  const handleSearch = async (personID: string) => {
    setPersonDetails(null);
    setPersonInfo(null);

    const isPersonId =
      /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/i.test(
        personID,
      );

    if (isPersonId) {
      setIsFromText(false);
      const queryParam = "personID";
      const promiseInfo = axiosGetPromise(
        axiosInstanceV3,
        "retrieve-person-info",
        null,
        personID,
        headers,
      );
      const promiseDetails = axiosGetPromise(
        axiosInstanceV3,
        "retrieve-person-details",
        queryParam,
        personID,
        headers,
      );

      Promise.all([promiseInfo, promiseDetails])
        .then((results) => {
          const [PersonInfoResult, PersonDetailsResult] = results;
          if (PersonInfoResult.code) {
            setPersonInfo(null);
            setPersonDetails(null);
            setSnackbarMessage("Enter Valid Person ID");
          } else {
            setPersonInfo(mergePrivacyConsentDefaults(PersonInfoResult));
            setPersonDetails(PersonDetailsResult);
          }
        })
        .catch((error) => {
          console.error("Fetch error:", error);
          setErrorDetails({
            message: "An error occurred while fetching person details.",
          });
          setErrorPopupOpen(true);
        });
    } else {
      setIsFromText(true);
      getTextResults(
        personID,
        false,
        code,
        setTextSearchResults,
        (data: {}) => alertFunction("updateTextSearchSuccess", data),
        (error: {}) => alertFunction("updateError", error),
      );
    }
  };

  // Adjusting privacy consent based on residence address
  const updatePrivacyConsentBasedOnLocation = async (
    personInfo: PersonInfoResponse,
    data: Audited<Residence>,
  ) => {
    const adjustedPrivacyConsentData = adjustPrivacyConsentDataByState(
      personInfo.privacyConsent,
      data.address,
    );
    const willUpdatePrivacyConsentData =
      adjustedPrivacyConsentData !== personInfo.privacyConsent;

    await handleUpdate(
      "modify-person-residence",
      data,
      "Residential Address updated successfully!",
      !willUpdatePrivacyConsentData,
    );
    // update privacy consent if stateProvince changes
    if (willUpdatePrivacyConsentData) {
      await handleUpdate(
        "confirm-privacy-consent",
        {
          ...adjustedPrivacyConsentData,
          country: countryCode,
          personId: personID,
        },
        "Residential Address updated successfully!",
        true,
      );
    }
  };

  // handle Update data
  const handleUpdate = async (
    updatePath: string,
    updateData: UpdateData,
    onSuccessMessage: string,
    reloadData = true,
  ) => {
    const fullUpdateData = {
      ...updateData,
      audit: {
        ...updateData.audit,
        createdByChannel: "Desktop",
        createdByUser: username,
        createdAtLocationNumber: warehouseLocation,
        mgloTransactionType: "MGLOUpdate",
        mgloTransactionId: generateUUID(),
        createdOnUtcDateTime: new Date().toISOString(),
      },
    };

    try {
      await axiosPutPromise(
        axiosInstanceV3,
        updatePath,
        fullUpdateData,
        headers,
      );
      if (reloadData) {
        await sleep(2000);
        await handleSearch(personID);
        setSnackbarOpen(true);
        setSeverity("success");
        setSnackbarMessage(onSuccessMessage);
      }
    } catch (error) {
      console.error("Action error:", error);
    }
  };

  // handle Actions Data
  const handleAction = async (actionPath: string, onSuccessMessage: string) => {
    const actionData = {
      personId: personID,
      mgloTransactionId: generateUUID(),
      mgloTransactionType: "MGLOUpdate",
    };
    try {
      await axiosPostPromise(axiosInstanceV3, actionPath, actionData, headers);
      setSnackbarMessage(onSuccessMessage);
      setSeverity("success");
      setSnackbarOpen(true);
      await sleep(2000);
      await handleSearch(personID);
    } catch (error) {
      console.error("Action error:", error);
      setErrorPopupOpen(true);
    }
  };

  return (
    <Container disableGutters>
      {!fromAnySearch && (
        <SearchComponent
          callback={() => handleSearch(personID)}
          label={"Enter Person ID"}
          handleIdChange={handlePersonIDChange}
        />
      )}
      <Container disableGutters sx={styles.cardWidth}>
        <Grid container spacing={3}>
          <Grid item xs={2} sm={2} sx={{ mb: 1, mt: 2 }}>
            <CountryPickerComponent
              countryCode={countryCode}
              setCountryCode={setCountryCode}
              fromItemsMenu={false}
              required={true}
              countryList={["USA", "CAN"]}
            />
          </Grid>
        </Grid>
      </Container>

      {personInfo && !isFromText ? (
        <Container disableGutters sx={styles.cardWidth}>
          {personInfo && isProtectedPerson && (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} sx={{ mb: 1 }}>
                <Alert severity="warning">
                  This person's personal information cannot be viewed or
                  modified, without a supervisor override request.
                </Alert>
              </Grid>
            </Grid>
          )}
          <PersonProfile
            personInfo={personInfo?.profile}
            personDetails={personDetails}
            isProtectedPerson={isProtectedPerson}
            onUpdate={(data) =>
              handleUpdate(
                "modify-person-profile",
                data,
                "Profile updated successfully!",
              )
            }
          />
          <PersonContact
            personInfo={personInfo.contacts}
            countryCode={countryCode}
            isProtectedPerson={isProtectedPerson}
            onUpdate={(data) => {
              handleUpdate(
                "modify-person-contacts",
                data,
                "Contacts updated successfully!",
              );
            }}
          />
          <PersonAddress
            personInfo={personInfo.residence}
            personDetails={personDetails}
            handleSearch={handleSearch}
            isProtectedPerson={isProtectedPerson}
            onUpdate={(residence) =>
              updatePrivacyConsentBasedOnLocation(personInfo, residence)
            }
          />
          <PersonPrivacyConsent
            personPrivacyConsent={personInfo.privacyConsent}
            personPrivacyConsentResidence={personInfo.residence}
            onUpdate={(data) => {
              handleUpdate(
                "confirm-privacy-consent",
                { ...data, country: countryCode, personId: personID },
                "Privacy Consent updated successfully!",
              );
            }}
          />
          <PersonMarketingPreference
            personInfo={personInfo.marketingPref}
            isProtectedPerson={isProtectedPerson}
            onUpdate={(data) => {
              handleUpdate(
                "confirm-person-marketing-preferences",
                data,
                "Marketing preferences updated successfully!",
              );
            }}
          />
          {personDetails && (
            <PersonMember
              personDetailsMembers={personDetails.members}
              isProtectedPerson={isProtectedPerson}
            />
          )}
          {personDetails && (
            <PersonActions
              personDetails={personDetails}
              isProtectedPerson={isProtectedPerson}
              onAction={handleAction}
            />
          )}
        </Container>
      ) : (
        isFromText &&
        textSearchResults?.results && (
          <TextSearchResultsComponent data={textSearchResults?.results} />
        )
      )}

      <ErrorPopupComponent
        open={errorPopupOpen}
        onClose={handleErrorClose}
        errorDetails={errorDetails}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default RetrievePerson;
